import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Pokemon from "../components/pokemon"

const PokemonListPage = ({ data, location }) => {
  const [search, setSearch] = React.useState("")
  const rawPokemons = data.allMarkdownRemark.edges
  const [pokemons, setPokemons] = React.useState(rawPokemons)
  const [isLoading, setIsLoading] = React.useState(true)
  const siteTitle = data.site.siteMetadata.title

  React.useLayoutEffect(() => {
    setPokemons(
      rawPokemons.filter(pokemon => {
        if (!search || search.length <= 2) {
          return true
        } else {
          return pokemon.node.frontmatter.name.toLowerCase().includes(search)
        }
      })
    )
  }, [search])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Ground Type Pokémon GO"
        description="Everything you need to know about Ground type Pokémons in Pokémon GO: who are they? How many Ground Pokémon? What are their Weaknesses? How powerful are they?"
        slug={location.pathname}
      />

      <h1>Ground Type Pokémon GO</h1>

      <p>There are {pokemons.length} ground types in Pokemon GO.</p>

      <p>
        As seen on our{" "}
        <Link to="/pokemon-go-type-chart/">Pokémon GO Type Chart</Link>, ground
        type Pokémons are weak against GRASS ICE WATER and resistant to POISON
        ROCK attacks and double resistant to ELECTRIC.
      </p>

      <h2>Best Ground Type Pokemon GO</h2>

      <p>
        Here is a list of all the Ground Type Pokemons currently implemented in
        Pokemon GO:
      </p>

      <input
        value={search}
        onChange={e => setSearch(e.target.value)}
        style={{ width: "100%" }}
        placeholder="Search for a Ground Type Pokemon..."
      />

      <div>
        {pokemons.map(pokemon => {
          return (
            <Link
              key={`${pokemon.node.frontmatter.id}-${pokemon.node.frontmatter.form}`}
              to={`/pokemon-go/${
                pokemon.node.frontmatter.id
              }-${pokemon.node.frontmatter.name.toLowerCase()}/`}
            >
              <Pokemon pokemon={pokemon.node.frontmatter} />
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default PokemonListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___id], order: ASC }
      filter: {
        fileAbsolutePath: {
          regex: "/.*/pokemon/(27|28|31|34|50|51|74|75|76|95|104|105|111|112|194|195|207|208|220|221|231|232|246|247|259)-.*/"
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            id
            name
            form
            type1
            type2
            maxCp
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
